
import { Vue, Component } from "vue-property-decorator";
import { mapMutations, mapGetters } from "vuex";
import {
    ResponseDataChart as responseChart,
    Chart,
    ResponseDataStringArray as rdsa
} from "@/interfaces";
import * as types from "@/store/types";
@Component({
    components: {
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        PlotlyChart: () => import("@/components/charts/PlotlyChart.vue")
    },
    methods: {
        ...mapMutations({
            authLogin: types.AUTH_LOGIN,
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER
        })
    }
})
export default class Prices extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }

    companies: string[] = [];
    company = "";
    clients: string[] = [];
    client = "";
    installations: string[] = [];
    install = "";
    datadisChart: Chart = { data: [] };
    username = "";
    password = "";

    role = Vue.prototype.$user.get().role;
    userIdentifier?: number;
    loading = false;

    mounted() {
        this.fetchUser();
    }

    async fetchUser() {
        if (this.role == "client") {
            try {
                const userClientResponse = await this.$api.SQLuserClient<rdsa>(
                    this.userIdentifier
                );
                if (userClientResponse.ok) {
                    this.company = userClientResponse.data[0];
                    this.client = userClientResponse.data[1];
                    this.fetchInstallation();
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            }
        } else if (this.role == "admin") {
            this.fetchCompany();
        } else {
            console.log("Role undefined");
        }
    }

    async fetchCompany() {
        try {
            const companyResponse = await this.$api.SQLcompany<rdsa>();
            if (companyResponse.ok) {
                this.companies = companyResponse.data;
                this.company = this.companies[0];
                this.fetchClient();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async fetchClient() {
        try {
            const clientResponse = await this.$api.SQLclient<rdsa>(
                this.company.split(" - ")[0]
            );
            if (clientResponse.ok) {
                this.clients = clientResponse.data;
                this.client = this.clients[0];
                this.fetchInstallation();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async fetchInstallation() {
        try {
            const installResponse = await this.$api.SQLinstallation<rdsa>(
                this.client.split(" - ")[0]
            );
            if (installResponse.ok) {
                this.installations = installResponse.data;
                this.install = this.installations[0];
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async Verify() {
        try {
            this.loading = true;
            this.datadisChart = { data: [] };

            const Ncliente =
                this.userIdentifier == 143 ? 42 : this.userIdentifier;
            const datadisGraph = await this.$api.verifyDatadis<responseChart>(
                Ncliente
            );
            if (datadisGraph.ok) {
                this.datadisChart = datadisGraph.data.fig;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.loading = false;
        }
    }

    async Synchronize() {
        try {
            const datadisGraph = await this.$api.synchronDatadis<responseChart>(
                this.username,
                this.password,
                this.company.split(" - ")[0],
                this.client.split(" - ")[0],
                this.install.split(" - ")[0]
            );
            if (datadisGraph.ok) {
                "do nothing";
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    resetForm0() {
        this.clients = [];
        this.client = "";
        this.installations = [];
        this.install = "";
    }

    resetForm1() {
        this.installations = [];
        this.install = "";
    }

    clear() {
        this.clients = [];
        this.client = "";
        this.datadisChart = { data: [] };
        this.username = "";
        this.password = "";
    }

    clear1() {
        this.datadisChart = { data: [] };
        this.username = "";
        this.password = "";
    }
}
